import React, { useContext } from 'react';
import TextInput from '../../../builder/editors/components/TextInput';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { find, indexOf, map } from 'lodash';
import WysiwygEditor from '../../../../components/common/WysiwygEditor';
import { initialCustomBlockContent } from '../reducer';
import { StateContext } from '../../../../App';
import { useLocation } from 'react-router-dom';

const CustomBlockForm = ({ customBlock, formErrors, onUpdateDescription, onUpdateContent, context }) => {
  const globalState = useContext(StateContext);
  const location = useLocation();

  const customBlockLanguage = location?.state?.language ? location.state.language : context.culture;
  const languages = globalState?.configuration?.website?.languages;

  const tabClassNames = 'relative -bottom-px builder-p-3 builder-cursor-pointer builder-text-black builder-text-md builder-uppercase focus:outline-none';
  const activeTabClassNames = 'builder-font-bold builder-bg-white builder-border-t builder-border-r builder-border-l builder-border-b-0 builder-rounded-sm builder-border-gray-300';
  const tabPanelClassNames = 'builder-bg-white builder-border-l builder-border-r builder-border-gray-300';

  return (
    <div className='builder-flex builder-p-5 builder-w-full builder-h-full builder-bg-gray-50'>
      <div className='builder-flex builder-flex-col builder-mb-5 builder-w-full'>
        <div className='builder-flex builder-flex-col builder-mb-5'>
          <div className='builder-text-black builder-text-md builder-font-bold builder-mb-2'>Description</div>
          <TextInput
            dataTestId='custom-block-detail-description-input'
            placeHolder='Add description'
            className={`builder-flex builder-flex-1 builder-border builder-w-full builder-rounded-md ${formErrors['adDescription'] ? 'builder-border-red-500' : 'builder-border-gray-300'}`}
            value={customBlock.description}
            onChanged={(value) => onUpdateDescription(value)}
          />
          {formErrors['customBlockDescription'] && <span className='builder-mt-1 builder-text-sm builder-text-red-500'>{formErrors['customBlockDescription']}</span>}
        </div>
        <Tabs defaultIndex={indexOf(languages, customBlockLanguage)} className='builder-h-full'>
          <TabList className='builder-flex builder-flex-row builder-justify-start builder-align-center builder-border-b builder-border-gray-300'>
            {map(languages, (language) => (
              <Tab key={`tab-list-language-${language}`} className={tabClassNames} selectedClassName={activeTabClassNames}>
                {language}
              </Tab>
            ))}
          </TabList>

          {map(languages, (language) => {
            const currentCustomBlockContent = find(customBlock?.content, (content) => content.culture === language);
            return (
              <TabPanel key={`tab-panel-language-${language}`} className={tabPanelClassNames}>
                <div className='builder-pt-2'>
                  <WysiwygEditor data={currentCustomBlockContent?.data || initialCustomBlockContent.data} onChange={(newContent) => onUpdateContent(language, newContent)} />
                </div>
              </TabPanel>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default CustomBlockForm;
